


function updateTotalVirementsExport(table,export_ids) {

	total_string = ""
	index = 0
	export_ids.forEach(function(export_id) {
		var total = 0

		table.find("tbody tr.virement-line").each(function() {
			var montant_currency = $(this).find("td.montant").data("montant")
			var line_currency = $(this).find("td.montant").data("currency")
			var line_export_id = $(this).find("td.export").data("export")
			var isChecked = $(this).find(".virement-checkbox").is(":checked")


			if(isChecked && line_export_id == export_id) {
				total = total + parseFloat(montant_currency)
			}
		})


		if(index > 0) {
			total_string = total_string + " / " + total + " " + currency 
		} else {
			total_string = total_string + " " + total + " " + currency 
		}
		index = index + 1
	})


	table.find(".virements-total").find("span").text(total_string)

}


function updateTotalVirementsMultiCurrency(table) {


	var currencies = table.find("tr.total").data("currencies")
	currencies = currencies.toString().split(",")


	total_string = ""
	index = 0
	currencies.forEach(function(currency) {
		var total = 0

		table.find("tbody tr.virement-line").each(function() {
			var montant_currency = $(this).find("td.montant").data("montant")
			var line_currency = $(this).find("td.montant").data("currency")
			var isChecked = $(this).find(".virement-checkbox").is(":checked")


			if(isChecked && line_currency == currency) {
				total = total + parseFloat(montant_currency)
			}
		})


		if(index > 0) {
			total_string = total_string + " / " + total.toFixed(2) + " " + currency 
		} else {
			total_string = total_string + " " + total.toFixed(2) + " " + currency 
		}
		index = index + 1
	})


	table.find(".virements-total").find("span").text(total_string)


}

document.addEventListener('turbolinks:load', () => {   


	////////////////////////////////////////////////////////////////////////////
	///	Remboursements
	////////////////////////////////////////////////////////////////////////////

	var markAsDoneString = ''
	var markAsDoneLink = $("#remboursement-mark-as-done").attr("href")
	var chequeRef
	var chequeDate

	$('#remboursement-mark-as-done-num-cheque').on('keyup', function() {

		chequeRef = $(this).val()

		markAsDoneString = "?date=" + chequeDate + '&ref=' + chequeRef

		$("#remboursement-mark-as-done").attr("href", markAsDoneLink + markAsDoneString)


	})


	$('#remboursement-mark-as-done-date').pickadate({
	  formatSubmit: 'yyyy-mm-dd',
	  hiddenSuffix: '',
	  hiddenName: true,
	  onClose: function () {
	      this.$holder.blur();
	  },
	  onSet: function(context) {
	      chequeDate = new Date(context.select).toLocaleDateString('en-CA');


	      markAsDoneString = "?date=" + chequeDate + '&ref=' + chequeRef

	      $("#remboursement-mark-as-done").attr("href", markAsDoneLink + markAsDoneString)
	      $("#remboursement-mark-as-done").addClass("active")

	  }
	});



	$('.remboursement-mark-as-rejected-date').each(function() {

		var markAsRejectedString = ''
		var markAsrejectedButton = $(this).next(".remboursement-mark-as-rejected")
		var markAsRejectedLink = markAsrejectedButton.attr("href")

		$(this).pickadate({
			  formatSubmit: 'yyyy-mm-dd',
			  hiddenSuffix: '',
			  hiddenName: true,
			  onClose: function () {
			      this.$holder.blur();
			  },
			  onSet: function(context) {
			      dateRejet = new Date(context.select).toLocaleDateString('en-CA');

			      markAsrejectedString = "?date=" + dateRejet

			      markAsrejectedButton.attr("href", markAsRejectedLink + markAsrejectedString)
			      markAsrejectedButton.addClass("active")
			      markAsrejectedButton.removeClass("disabled")


			  }
		});

		markAsrejectedButton.on("click", function(e) {
		if(markAsRejectedString == '') {
			e.preventDefault()
		}
	})

	})






	////////////////////////////////////////////////////////////////////////////
	///	Tableaux d'export
	////////////////////////////////////////////////////////////////////////////


	// Renseignement des exports à cocher

	if($("#virements-export-xml-form").length > 0) {

		var currencies = $("#virements-export-xml-form").find("tr.total").data("currencies")
		currencies = currencies.toString().split(",")
		currencies.forEach(function(currency) {
			$("#currency-check").append('<label><input type="checkbox" class="currency-check" data-currency="' + currency + '">&nbsp;Tous les ' + currency + '</label>')
		})

		var compagnies = $("#virements-export-xml-form").find("tr.total").data("compagnies")
		if(compagnies) {
			compagnies = compagnies.toString().split(",")
			compagnies.forEach(function(compagnie) {
				$("#currency-check").append('<label><input type="checkbox" class="compagnie-check" data-compagnie="' + compagnie + '">&nbsp;Tous les ' + compagnie + '</label>')
			})
		}

	}

	// Renseignement des exports à cocher

	if($("#virement-export-compta-form").length > 0) {
		var exports_compta = $("#virement-export-compta-form").find("tr.total").data("export-ids")
		exports_compta = exports_compta.toString().split(",")
		exports_compta.forEach(function(export_compta) {
			$("#virement-export-compta-form").find("#export-check").append('<label><input type="checkbox" class="export-check" data-export="' + export_compta + '">&nbsp;Export #' + export_compta + '</label>')
		})
	}



	var xmlTable = $("#remboursement-table-xml")
	var comptaTable = $("#remboursement-table-compta")
	var comptaTableManuel = $("#remboursement-table-compta-manuel")
	var deboursTable = $("#remboursement-table-debours")


	// Sélectionner tout tableau XML

	$("#check-all-xml").on("change", function() {

		if(this.checked) {
			$(".currency-check").prop("checked",false)
			$("#remboursement-table-xml").find('input[type="checkbox"]:enabled').prop("checked",true)

		} else {
			$("#remboursement-table-xml").find('input[type="checkbox"]:enabled').prop("checked",false)
		}

		updateTotalVirementsMultiCurrency(xmlTable)
		$("#export-xml-virements-submit").val("Générer les fichiers XML pour ces lots")
	})

	var comptaCheckBoxesLength = $("#virement-export-form").find(".virement-checkbox:checked").length

	// Sélectionner tout tableau export compta

	$("#check-all-compta").on("change", function() {
		if(this.checked) {
			$(".export-check").prop("checked",false)
			$("#remboursement-table-compta").find('input[type="checkbox"]:enabled').prop("checked",true)

		} else {
			$("#remboursement-table-compta").find('input[type="checkbox"]:enabled').prop("checked",false)
		}

		updateTotalVirementsMultiCurrency(comptaTable)
	})

	// Selectionner tout tableau export compta hors SEPA
	$("#check-all-compta-manuel").on("change", function() {
		if(this.checked) {
			$(".export-check").prop("checked",false)
			$("#remboursement-table-compta-manuel").find('input[type="checkbox"]:enabled').prop("checked",true)

		} else {
			$("#remboursement-table-compta-manuel").find('input[type="checkbox"]:enabled').prop("checked",false)
		}

		updateTotalVirementsMultiCurrency(comptaTableManuel)
	})

	// Sélectionner tout tableau export débours

	$("#check-all-debours").on("change", function() {
		if(this.checked) {
			$(".export-check").prop("checked",false)
			$("#remboursement-table-debours").find('input[type="checkbox"]:enabled').prop("checked",true)

		} else {
			$("#remboursement-table-debours").find('input[type="checkbox"]:enabled').prop("checked",false)
		}

		updateTotalVirementsMultiCurrency(deboursTable)
	})



	// Filtrage par devise

	$(".currency-check").on("change", function() {
		var currency = $(this).data("currency")

		$("#check-all-xml").prop("checked",false).trigger("change")

		var checkedCurrencies = [];
		var uncheckedCurrencies = [];
		$(".currency-check").each(function() {
			if (this.checked) {
				checkedCurrencies.push($(this).data("currency"))
			} else {
				uncheckedCurrencies.push($(this).data("currency"))
			}
		})

		checkedCurrencies.forEach(function(currency) {
			$("#remboursement-table-xml").find('td.montant[data-currency=' + currency + ']').closest("tr").find('input[type="checkbox"]').prop("checked",true)
		})

		uncheckedCurrencies.forEach(function(currency) {
			$("#remboursement-table-xml").find('td.montant[data-currency=' + currency + ']').closest("tr").find('input[type="checkbox"]').prop("checked",false)
		})


		updateTotalVirementsMultiCurrency(xmlTable)

		$("#export-virements-xml-submit").val("Générer le fichier XML pour ce(s) lot(s)")

	})

	// Filtrage par compagnie

	$(".compagnie-check").on("change", function() {
		var compagnie = $(this).data("compagnie")

		$("#check-all-xml").prop("checked",false).trigger("change")

		var checkedCompagnies = [];
		var uncheckedCompagnies = [];
		$(".compagnie-check").each(function() {
			if (this.checked) {
				checkedCompagnies.push($(this).data("compagnie"))
			} else {
				uncheckedCompagnies.push($(this).data("compagnie"))
			}
		})

		checkedCompagnies.forEach(function(compagnie) {
			$("#remboursement-table-xml").find('td.montant[data-compagnie=' + compagnie + ']').closest("tr").find('input[type="checkbox"]').prop("checked",true)
		})

		uncheckedCompagnies.forEach(function(compagnie) {
			$("#remboursement-table-xml").find('td.montant[data-compagnie=' + compagnie + ']').closest("tr").find('input[type="checkbox"]').prop("checked",false)
		})



		updateTotalVirementsMultiCurrency(xmlTable)

		$("#export-virements-xml-submit").val("Générer le fichier XML pour ce lot")

	})

	// Filtrage par ID d'export

	$("#virement-export-compta-form").find(".export-check").on("change", function() {
		var export_id = $(this).data("export")

		console.log("CHANGE")

		$("#check-all-compta").prop("checked",false).trigger("change")

		var checkedExports = [];
		var uncheckedExports = [];
		$("#virement-export-compta-form").find(".export-check").each(function() {
			if (this.checked) {
				checkedExports.push($(this).data("export"))
			} else {
				uncheckedExports.push($(this).data("export"))
			}
		})

		checkedExports.forEach(function(exportId) {
			$("#remboursement-table-compta").find('td.export[data-export=' + exportId + ']').closest("tr").find('input[type="checkbox"]').prop("checked",true)
		})

		uncheckedExports.forEach(function(exportId) {
			$("#remboursement-table-compta").find('td.export[data-export=' + exportId + ']').closest("tr").find('input[type="checkbox"]').prop("checked",false)
		})


		updateTotalVirementsMultiCurrency(comptaTable)

	})


	// Sélection virement individuel

	comptaTable.find(".virement-checkbox").on("click", function() {

		updateTotalVirementsMultiCurrency(comptaTable)

	})

	comptaTableManuel.find(".virement-checkbox").on("click", function() {

		updateTotalVirementsMultiCurrency(comptaTableManuel)

	})

	xmlTable.find(".virement-checkbox").on("click", function() {

		updateTotalVirementsMultiCurrency(xmlTable)

	})

	deboursTable.find(".virement-checkbox").on("click", function() {

		updateTotalVirementsMultiCurrency(deboursTable)

	})


	// Vérif validation export XML

	var formXML = $("#virements-export-xml-form")
	var formXMLSubmit = $("#virements-export-xml-submit")
	var formXMLAlert = $("#virements-export-xml-alert")

	formXMLSubmit.on("click", function(e) {

		var XMLCheckBoxesLength = xmlTable.find(".virement-checkbox:checked").length

		e.preventDefault()
		if(XMLCheckBoxesLength == 0) {
			formXMLAlert.show()
		} else {
			formXMLAlert.hide()
			formXML.submit()
		}
	})


	// Vérif validation export compta

	var formCompta = $("#virement-export-compta-form")
	var formComptaSubmit = $("#virements-export-compta-submit")
	var formComptaAlert = $("#virements-export-compta-alert")

	formComptaSubmit.on("click", function(e) {

		var comptaCheckBoxesLength = comptaTable.find(".virement-checkbox:checked").length

		e.preventDefault()
		if(comptaCheckBoxesLength == 0) {
			formComptaAlert.show()
		} else {
			formComptaAlert.hide()
			formCompta.submit()
		}
	})

	// Vérif validation export compta hors SEPA

	var formComptaManuel = $("#virement-export-compta-manuel-form")
	var formComptaManuelSubmit = $("#virements-export-compta-manuel-submit")
	var formComptaManuelAlert = $("#virements-export-compta-manuel-alert")

	formComptaManuelSubmit.on("click", function(e) {

		var comptaCheckBoxesLength = comptaTableManuel.find(".virement-checkbox:checked").length

		e.preventDefault()
		if(comptaCheckBoxesLength == 0) {
			formComptaManuelAlert.show()
		} else {
			formComptaManuelAlert.hide()
			formComptaManuel.submit()
		}
	})



	// Vérif validation export debours

	const formDebours = $("#virement-export-debours-form")
	const formDeboursSubmit = $("#export-virements-debours-submit")
	const formDeboursAlert = $("#virements-export-debours-alert")


	formDeboursSubmit.on("click", function(e) {
		e.preventDefault()

		var deboursCheckBoxesLength = deboursTable.find(".virement-checkbox:checked").length

		if(deboursCheckBoxesLength == 0) {
			formDeboursAlert.show()
		} else {
			formDeboursAlert.hide()
			formDebours.submit()
		}
	})


})